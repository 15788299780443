/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';
import Seo from '../shared/Seo';

// Lib UI components

// Local UI components
const PageLayout = React.lazy(() => import('../shared/PageLayout'));
const SarlFolder = React.lazy(() => import('../page-components/SarlFolder'));

// Style

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function SarlFolderPage() {
  const isSSR = typeof window === 'undefined';
  /* ********************************** HOOKS ********************************* */

  // Localization

  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <Seo title="sarl folder" />
      {!isSSR && (
        <React.Suspense fallback={null}>
          <PageLayout>
            <SarlFolder />
          </PageLayout>
        </React.Suspense>
      )}
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["SarlFolder", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default SarlFolderPage;
